import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Button from 'components/Button';
import DoubleChevronRight from 'svg/double-chevron-right.svg';
import PropTypes from 'prop-types';
import PlaceSearch from '@molecules/FilterHeader/PlaceSearch';
import FilterHeaderContainer from '@molecules/FilterHeader/FilterHeaderContainer';
import Autocomplete from '@molecules/FilterHeader/Autocomplete';
import { useFilterHeader } from 'utils';

const FilterHeader = ({
  extraClasses,
  numberOfVacancies,
  autoCompleteLabel,
  autoCompletePlaceholder,
  autoCompleteIndex,
  placeLabel,
  placePlaceholder,
  submitLabel,
  withPlaceInput,
  homepageFilter,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    inputs,
    handleChange,
    onSelect,
    clearInput,
    location,
    setLocation,
    autocompleteSuggestions,
    loading,
    handleSubmit,
  } = useFilterHeader({ homepageFilter, autoCompleteIndex });

  return (
    <FilterHeaderContainer extraClasses={extraClasses}>
      {homepageFilter && (
        <h2 className="c-search-card__title h3">
          {numberOfVacancies && (
            <>
              <mark
                className="c-search-card__highlight"
                data-cy="number-of-vacancies"
              >
                {numberOfVacancies}
              </mark>{' '}
            </>
          )}
          {t('homepage.vacanciesByDirectEmployers')}
        </h2>
      )}
      <form
        className="c-search-card__form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="o-layout o-layout--gutter-small o-layout--align-bottom">
          <div className="o-layout__cell o-layout__cell--fill@from-md">
            <Autocomplete
              id="autocomplete"
              name="autocomplete"
              label={autoCompleteLabel}
              placeholder={autoCompletePlaceholder}
              value={inputs.autocomplete}
              loading={loading}
              onChange={handleChange}
              onSelect={onSelect}
              clearInput={clearInput}
              autocompleteSuggestions={autocompleteSuggestions}
              handleSubmit={handleSubmit}
              disableAutoSubmit={homepageFilter}
            />
          </div>

          {withPlaceInput && (
            <div className="o-layout__cell o-layout__cell--fill@from-md">
              <PlaceSearch
                label={placeLabel}
                placeholder={placePlaceholder}
                value={location.place}
                onChange={setLocation}
              />
            </div>
          )}

          <div className="o-layout__cell o-layout__cell--fit@from-md u-margin-left-auto@until-md">
            <Button
              type="submit"
              label={submitLabel}
              Icon={DoubleChevronRight}
              extraClasses="c-button--chevron c-button--hover-white c-search-card__button u-margin-top-small@until-md"
              test="submit-filter"
              rawLabel
            />
          </div>

          {homepageFilter && (
            <div className="o-layout__cell">
              <Button
                type="button"
                label={t('homepage.searchAllVacancies')}
                Icon={DoubleChevronRight}
                extraClasses="c-button--chevron c-button--link-white c-button--small u-display-block u-margin-left-auto@from-md"
                onClick={() => router.push('/bladeren')}
              />
            </div>
          )}
        </div>
      </form>
    </FilterHeaderContainer>
  );
};

FilterHeader.propTypes = {
  extraClasses: PropTypes.string,
  numberOfVacancies: PropTypes.number,
  autoCompleteIndex: PropTypes.string,
  autoCompleteLabel: PropTypes.string,
  autoCompletePlaceholder: PropTypes.string,
  placeLabel: PropTypes.string,
  placePlaceholder: PropTypes.string,
  submitLabel: PropTypes.string,
  withPlaceInput: PropTypes.bool,
  homepageFilter: PropTypes.bool,
};

FilterHeader.defaultProps = {
  autoCompleteIndex: 'vacancy',
};

export default FilterHeader;
