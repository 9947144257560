import PropTypes from 'prop-types';

function FormRow({ id, label, required, children }) {
  return (
    <div className="c-form-row">
      <label className="c-form__label" htmlFor={id}>
        <span className="c-form__label-inner">
          {`${label}`}
          {required && '*'}
        </span>
      </label>
      <div className="c-form__input-container">{children}</div>
    </div>
  );
}

FormRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export default FormRow;
