import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autowhatever from 'react-autowhatever';
import FormRow from '@molecules/FormRow/FormRow';
import SearchIcon from 'svg/search.svg';
import CrossIcon from 'svg/clear-autocomplete.svg';
import { getHighlightedText } from 'utils';
import Button from 'components/Button';
import { useTranslation } from 'next-i18next';

function Autocomplete({
  id,
  name,
  label,
  placeholder,
  loading,
  value,
  onChange,
  onSelect,
  clearInput,
  autocompleteSuggestions,
  handleSubmit,
  disableAutoSubmit,
}) {
  const { t } = useTranslation();
  const autocompleteHintId = `${id}-hint`;
  const inputEl = useRef(null);
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [highlightedItemIndex, setHighlightedItemIndex] = useState();
  const [items, setItems] = useState(autocompleteSuggestions);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleClearInput = () => {
    clearInput();
    setHighlightedItemIndex();
    if (inputEl.current) {
      inputEl.current.focus();
    }
  };

  const handleItemSelect = itemIndex => {
    // Pass the selected item to the onSelect function
    onSelect(items[highlightedItemIndex || itemIndex]);
    // Hide the autocomplete container
    setItems([]);
    // Setting this state will trigger the useEffect to submit,
    // We cannot call the handlesubmit directly because of the state update happens in the same render
    setShouldSubmit(true);
  };

  const onFocus = () => {
    // Show the autocomplete container with the suggestions
    setItems(autocompleteSuggestions);
    setInputHasFocus(true);
  };

  const onBlur = () => {
    // Hide the autocomplete container
    setItems([]);
    setInputHasFocus(false);
    setHighlightedItemIndex();
  };

  const onKeyDown = (e, { newHighlightedItemIndex }) => {
    // Select the suggestion on enter
    if (e.key === 'Enter' && highlightedItemIndex !== undefined) {
      e.preventDefault();
      handleItemSelect();
      setHighlightedItemIndex();
    }
    if (e.key === 'Enter') {
      setItems([]);
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
    // Use the up and down arrow to change highlight index
    if (typeof newHighlightedItemIndex !== 'undefined') {
      setHighlightedItemIndex(newHighlightedItemIndex);
    }
  };

  const onMouseEnter = (_, { itemIndex }) => {
    setHighlightedItemIndex(itemIndex);
  };
  const onMouseLeave = () => {
    setHighlightedItemIndex();
  };
  const onMouseDown = (_, { itemIndex }) => {
    handleItemSelect(itemIndex);
  };

  // Update the items var whenever we receive new suggestions
  useEffect(() => {
    setItems(autocompleteSuggestions);
  }, [autocompleteSuggestions]);

  useEffect(() => {
    if (!disableAutoSubmit && shouldSubmit && handleSubmit) {
      handleSubmit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  const renderItem = (suggestion, { value: inputValue }) =>
    getHighlightedText(suggestion, inputValue);

  const renderInputComponent = props => <input {...props} ref={inputEl} />;

  const inputProps = {
    id,
    name,
    placeholder,
    value,
    className: 'c-autowhatever__input',
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    'aria-describedby': autocompleteHintId,
  };

  const itemProps = ({ itemIndex }) => ({
    'data-item-index': itemIndex,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
  });

  return (
    <FormRow id={id} label={label}>
      <div
        className={`c-autowhatever${
          inputHasFocus ? ' c-autowhatever--is-focused' : ''
        }`}
      >
        <Autowhatever
          items={items}
          renderInputComponent={renderInputComponent}
          renderItem={renderItem}
          renderItemData={{ value }}
          highlightedItemIndex={highlightedItemIndex}
          inputProps={inputProps}
          itemProps={itemProps}
        />
        {clearInput && value && !loading && (
          <Button
            Icon={CrossIcon}
            extraClasses="c-button--transparent c-autowhatever__clear-button"
            onClick={handleClearInput}
            label="Verwijder zoekopdracht"
            hideLabel
          />
        )}
        {loading && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className="c-form__pending-spinner c-autowhatever__loader"
          >
            <circle
              cx="10"
              cy="10"
              r="8"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              pathLength="10"
              strokeLinecap="round"
            />
          </svg>
        )}
        <SearchIcon className="c-autowhatever__icon" />
        <span id={autocompleteHintId} className="c-autowhatever__hint">
          {t('autocomplete.hint')}
        </span>
      </div>
    </FormRow>
  );
}

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  clearInput: PropTypes.func,
  autocompleteSuggestions: PropTypes.array,
  handleSubmit: PropTypes.func,
  disableAutoSubmit: PropTypes.bool,
};

export default Autocomplete;
