import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import LogoIcon from 'svg/logo-icon.svg';

function FilterHeaderContainer({ extraClasses, children }) {
  const [isFloating, setIsFloating] = useState(false);

  const intersectionRef = useRef();
  useEffect(() => {
    if (!intersectionRef.current || typeof window === 'undefined') return;
    // If intersectionEl, observe and set floating to true when bottom of intersectionEl reaches top of page
    const observerCallback = entries =>
      entries.forEach(({ intersectionRatio }) => {
        const shouldFloat = intersectionRatio === 0;
        setIsFloating(shouldFloat);
      });
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: `-${intersectionRef.current.offsetHeight}px 0px 0px 0px`,
      threshold: [0, 0.001],
    });
    observer.observe(intersectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const getCardClasses = () => {
    let classes = [
      'c-card--blue',
      'c-search-card',
      'c-search-card--full-width',
      extraClasses,
    ];

    if (isFloating) {
      classes = [...classes, 'c-search-card--is-floating'];
    }

    return classes.filter(c => !!c).join(' ');
  };

  return (
    <>
      <Card extraClasses={getCardClasses()}>
        <div className="c-search-card__backdrop-container">
          <LogoIcon className="c-search-card__backdrop" />
        </div>
        <div className="o-retain o-retain--wall">{children}</div>
      </Card>
      <div ref={intersectionRef} />
    </>
  );
}

FilterHeaderContainer.propTypes = {
  extraClasses: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

FilterHeaderContainer.defaultProps = {
  extraClasses: '',
};

export default FilterHeaderContainer;
